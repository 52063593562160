import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Determines the appropriate language based on domain
 * @returns 'fr' if on nettoigo.ca, null otherwise
 */
export function getDomainLanguage(): string | null {
  const hostname = window.location.hostname;
  if (hostname === "nettoigo.ca") {
    return "fr";
  }
  return null;
}
