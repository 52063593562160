import { motion } from "framer-motion";
import { Leaf, Droplets, Recycle } from "lucide-react";

interface EcoLoadingScreenProps {
  isLoading?: boolean;
}

export function EcoLoadingScreen({ isLoading = true }: EcoLoadingScreenProps) {
  if (!isLoading) return null;

  const iconVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={containerVariants}
      className="fixed inset-0 bg-background flex items-center justify-center z-50"
    >
      <div className="text-center space-y-6">
        <motion.div
          className="flex items-center justify-center gap-4"
          variants={containerVariants}
        >
          <motion.div variants={iconVariants}>
            <Leaf className="w-8 h-8 text-green-500" />
          </motion.div>
          <motion.div variants={iconVariants}>
            <Droplets className="w-8 h-8 text-blue-500" />
          </motion.div>
          <motion.div variants={iconVariants}>
            <Recycle className="w-8 h-8 text-primary" />
          </motion.div>
        </motion.div>

        <motion.div
          variants={iconVariants}
          className="text-xl font-semibold text-primary"
        >
          NettoiGo
        </motion.div>

        <motion.div
          variants={iconVariants}
          className="text-sm text-muted-foreground"
        >
          Rendre le monde plus propre, une maison à la fois
        </motion.div>
      </div>
    </motion.div>
  );
}
