import * as React from "react";
import { useEffect, useState, lazy, Suspense } from "react";
import { Switch, Route, useLocation } from "wouter";
import { useUser } from "./hooks/use-user";
import { Loader2 } from "lucide-react";
import * as Swetrix from "swetrix";
import { useToast } from "@/components/ui/use-toast";
import { useTranslation } from "react-i18next";
import {
  storePreviousPageBeforeLogin,
  getPreviousPage,
  clearPreviousPage,
} from "@/lib/navigation";
import { checkDomainRedirect, getSiteName } from "@/lib/domain";

import { HelmetProvider } from "react-helmet-async";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { EcoLoadingScreen } from "./components/EcoLoadingScreen";

// Lazy load page components
const HomePage = lazy(() => import("./pages/HomePage"));
const AuthPage = lazy(() => import("./pages/AuthPage"));
const BookingPage = lazy(() => import("./pages/BookingPage"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const ProfileCalendarPage = lazy(() => import("./pages/ProfileCalendarPage"));
const PublicProfilePage = lazy(() => import("./pages/PublicProfilePage"));
const AdminDashboard = lazy(() => import("./pages/AdminDashboard"));
const AdminMarketing = lazy(() => import("./pages/AdminMarketing"));
const ForgotPasswordPage = lazy(() => import("./pages/ForgotPasswordPage"));
const ResetPasswordPage = lazy(() => import("./pages/ResetPasswordPage"));
const BookingConfirmation = lazy(() => import("./pages/BookingConfirmation"));
const BookingDetailsPage = lazy(() => import("./pages/BookingDetailsPage"));
const CleanersListPage = lazy(() => import("./pages/CleanersListPage"));
const CityLandingPage = lazy(() => import("./pages/CityLandingPage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const TermsPage = lazy(() => import("./pages/TermsPage"));
const PrivacyPage = lazy(() => import("./pages/PrivacyPage"));
const HowItWorksPage = lazy(() => import("./pages/HowItWorksPage"));
const PricingPage = lazy(() => import("./pages/PricingPage"));
const FAQPage = lazy(() => import("./pages/FAQPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const SystemHealthDashboard = lazy(
  () => import("./pages/SystemHealthDashboard")
);
const PayoutsPage = lazy(() => import("./pages/PayoutsPage"));
const BookingsListPage = lazy(() => import("./pages/BookingsListPage"));
const CommunityImpactPage = lazy(() => import("./pages/CommunityImpactPage"));
const MyReviewsPage = lazy(() => import("./pages/MyReviewsPage"));
const ReviewsPage = lazy(() => import("./pages/ReviewsPage"));
const PropertiesPage = lazy(() => import("@/pages/PropertiesPage"));
const ReferralsPage = lazy(() => import("@/pages/ReferralsPage"));
const ReferralProgramPage = lazy(() => import("@/pages/ReferralProgramPage"));
const HomeownerLandingPage = lazy(() => import("@/pages/HomeownerLandingPage"));
const CleanerLandingPage = lazy(() => import("@/pages/CleanerLandingPage"));
const BlogPage = lazy(() => import("./pages/blog"));
const BlogPostPage = lazy(() => import("./pages/blog/[slug]"));
const CleanerOnboarding = lazy(() =>
  import("@/pages/CleanerOnboarding").then((module) => ({
    default: (props) => (
      <Suspense fallback={<PageLoader />}>
        <module.default {...props} />
      </Suspense>
    ),
  }))
);
const ClientOnboarding = lazy(() => import("@/pages/ClientOnboarding"));
const GalleryPage = lazy(() => import("./pages/GalleryPage"));
const RegionLandingPage = lazy(() => import("@/pages/RegionLandingPage"));
const ColorSchemeMockupPage = lazy(
  () => import("@/pages/ColorSchemeMockupPage")
);
const ThemeTestPage = lazy(() => import("@/pages/ThemeTestPage"));
const PayoutsTestPage = lazy(() => import("./pages/admin/PayoutsTestPage"));
const UsersPage = lazy(() => import("./pages/admin/UsersPage"));
const ProfileReviewPage = lazy(() => import("./pages/admin/ProfileReviewPage"));
const CleanerAnalyticsPage = lazy(
  () => import("./pages/admin/CleanerAnalyticsPage")
);
// Service pages
const RegularCleaningPage = lazy(() => import("./pages/RegularCleaningPage"));
const DeepCleaningPage = lazy(() => import("./pages/DeepCleaningPage"));
const MoveInCleaningPage = lazy(() => import("./pages/MoveInCleaningPage"));
const GarageCleaningPage = lazy(() => import("./pages/GarageCleaningPage"));
import GoogleRoleSelectionPage from "./pages/GoogleRoleSelectionPage";

import { RequireClient } from "@/components/RequireClient";

Swetrix.init("vFqFfLCu0yba");
Swetrix.trackViews();

// Add the RedirectTo component near the top of the file, before the App component
function RedirectTo({ path }: { path: string }) {
  const [_, setLocation] = useLocation();

  useEffect(() => {
    try {
      // Handle potential empty or invalid paths
      const targetPath = path || "/";
      if (process.env.NODE_ENV === "development") {
        console.debug(`Redirecting to: ${targetPath}`);
      }
      setLocation(targetPath);
    } catch (error) {
      // Fallback to window.location if wouter navigation fails
      console.error("Navigation error:", error);
      console.warn("Using fallback navigation in development mode");
      window.location.href = path || "/";
    }
  }, [path, setLocation]);

  // Return null as this is a utility component
  return null;
}

// Component to handle URL parameters and display notifications
function NotificationHandler() {
  const { toast } = useToast();
  const { t } = useTranslation();
  const [location, setLocation] = useLocation();

  useEffect(() => {
    // Extract URL parameters
    const searchParams = window.location.search;
    const params = new URLSearchParams(searchParams);
    const message = params.get("message");
    const error = params.get("error");

    // Display success message if present
    if (message) {
      console.debug("Displaying success toast:", message);
      toast({
        title: t("common.success", "Success"),
        description: message,
      });

      // Remove the message parameter from URL to prevent showing the toast again on refresh
      if (window.history && window.history.replaceState) {
        const newParams = new URLSearchParams(searchParams);
        newParams.delete("message");
        const newUrl =
          window.location.pathname +
          (newParams.toString() ? `?${newParams.toString()}` : "");
        window.history.replaceState({}, "", newUrl);
      }
    }

    // Display error message if present
    if (error) {
      console.log("Displaying error toast:", error);
      toast({
        variant: "destructive",
        title: t("common.error", "Error"),
        description: error,
      });

      // Remove the error parameter from URL to prevent showing the toast again on refresh
      if (window.history && window.history.replaceState) {
        const newParams = new URLSearchParams(searchParams);
        newParams.delete("error");
        const newUrl =
          window.location.pathname +
          (newParams.toString() ? `?${newParams.toString()}` : "");
        window.history.replaceState({}, "", newUrl);
      }
    }
  }, [location, toast, t]);

  return null;
}

// Loading component for suspense fallback
const PageLoader = () => (
  <div className="flex items-center justify-center min-h-screen">
    <Loader2 className="h-8 w-8 animate-spin text-primary" />
  </div>
);

function App() {
  const { user, isLoading } = useUser();
  const [location, setLocation] = useLocation();
  const { t, i18n } = useTranslation();

  // Check if domain redirect is needed on initial load
  useEffect(() => {
    // Skip redirection during development
    if (process.env.NODE_ENV !== "production") return;

    // Only attempt redirection once on initial page load
    checkDomainRedirect();

    // Force French language on nettoigo.ca domain
    const hostname = window.location.hostname;
    if (hostname === "nettoigo.ca" && i18n.language !== "fr") {
      i18n.changeLanguage("fr");
    }
  }, [i18n]);

  useEffect(() => {
    // Store the current location when the component mounts or location changes
    // This will be used to redirect back after login
    storePreviousPageBeforeLogin(location);
  }, [location]);

  if (isLoading) {
    return <EcoLoadingScreen isLoading={true} />;
  }

  // Determine site name based on domain
  const siteName = getSiteName();

  // Routes that don't require authentication
  if (!user) {
    return (
      <HelmetProvider>
        <div className="min-h-screen flex flex-col">
          <NotificationHandler />
          <Switch>
            {/* Web routes */}
            <Route>
              <Header siteName={siteName} />
              <main className="flex-grow">
                <Suspense fallback={<PageLoader />}>
                  <Switch>
                    <Route path="/" component={HomePage} />
                    <Route
                      path="/homeowners"
                      component={HomeownerLandingPage}
                    />
                    <Route
                      path="/cleaners/join"
                      component={CleanerLandingPage}
                    />
                    <Route path="/about" component={AboutPage} />
                    <Route path="/how-it-works" component={HowItWorksPage} />
                    <Route path="/terms" component={TermsPage} />
                    <Route path="/privacy" component={PrivacyPage} />
                    <Route path="/pricing" component={PricingPage} />
                    <Route path="/faq" component={FAQPage} />
                    <Route path="/gallery" component={GalleryPage} />
                    <Route path="/contact" component={ContactPage} />
                    <Route path="/impact" component={CommunityImpactPage} />
                    <Route path="/cleaners" component={CleanersListPage} />
                    <Route
                      path="/cleaners/:slug"
                      component={PublicProfilePage}
                    />
                    <Route path="/city/:cityName" component={CityLandingPage} />
                    <Route
                      path="/region/:regionId"
                      component={RegionLandingPage}
                    />
                    <Route
                      path="/forgot-password"
                      component={ForgotPasswordPage}
                    />
                    <Route
                      path="/reset-password/:token"
                      component={ResetPasswordPage}
                    />
                    <Route
                      path="/bookings/:bookingId"
                      component={BookingDetailsPage}
                    />
                    <Route
                      path="/bookings/:id/:action"
                      component={BookingConfirmation}
                    />
                    <Route
                      path="/referral-program"
                      component={ReferralProgramPage}
                    />
                    <Route path="/reviews" component={ReviewsPage} />
                    <Route path="/blog" component={BlogPage} />
                    <Route path="/blog/:slug" component={BlogPostPage} />
                    <Route
                      path="/color-scheme-mockup"
                      component={ColorSchemeMockupPage}
                    />
                    <Route path="/theme-test" component={ThemeTestPage} />
                    <Route
                      path="/services/nettoyage-regulier"
                      component={RegularCleaningPage}
                    />
                    <Route
                      path="/services/nettoyage-profondeur"
                      component={DeepCleaningPage}
                    />
                    <Route
                      path="/services/nettoyage-demenagement"
                      component={MoveInCleaningPage}
                    />
                    <Route
                      path="/services/nettoyage-garage"
                      component={GarageCleaningPage}
                    />
                    <Route path="/auth/success">
                      {() => {
                        // Let the server handle the redirect logic
                        window.location.reload();
                        return null;
                      }}
                    </Route>
                    <Route path="/login">
                      {() => {
                        // When explicitly navigating to login, we're already storing the previous page
                        return <AuthPage />;
                      }}
                    </Route>
                    <Route path="/auth" component={AuthPage} />
                    <Route
                      path="/auth/select-role"
                      component={GoogleRoleSelectionPage}
                    />
                    <Route component={AuthPage} />
                  </Switch>
                </Suspense>
              </main>
              <Footer siteName={siteName} />
            </Route>
          </Switch>
        </div>
      </HelmetProvider>
    );
  }

  // add a new block for admin routes
  if (user?.role === "admin") {
    return (
      <div className="min-h-screen flex flex-col">
        <NotificationHandler />
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/admin" component={AdminDashboard} />
            <Route path="/admin/health" component={SystemHealthDashboard} />
            <Route path="/admin/users" component={UsersPage} />
            <Route path="/admin/profile-review" component={ProfileReviewPage} />
            <Route path="/admin/payouts" component={PayoutsTestPage} />
            <Route path="/admin/marketing" component={AdminMarketing} />
            <Route
              path="/admin/cleaners/:cleanerId"
              component={CleanerAnalyticsPage}
            />
            <Route path="/payouts" component={PayoutsPage} />
            <Route>
              {() => {
                // Use a component instead of direct state updates in render
                return <RedirectTo path="/admin" />;
              }}
            </Route>
          </Switch>
        </Suspense>
      </div>
    );
  }

  return (
    <HelmetProvider>
      <div className="min-h-screen flex flex-col">
        <NotificationHandler />
        <Switch>
          {/* Web routes */}
          <Route>
            <Header siteName={siteName} />
            <main className="flex-grow">
              <Suspense fallback={<PageLoader />}>
                <Switch>
                  <Route path="/" component={HomePage} />
                  <Route path="/about" component={AboutPage} />
                  <Route path="/homeowners" component={HomeownerLandingPage} />
                  <Route path="/cleaners/join" component={CleanerLandingPage} />
                  <Route path="/how-it-works" component={HowItWorksPage} />
                  <Route path="/terms" component={TermsPage} />
                  <Route path="/privacy" component={PrivacyPage} />
                  <Route path="/pricing" component={PricingPage} />
                  <Route path="/faq" component={FAQPage} />
                  <Route path="/gallery" component={GalleryPage} />
                  <Route path="/contact" component={ContactPage} />
                  <Route
                    path="/color-scheme-mockup"
                    component={ColorSchemeMockupPage}
                  />
                  <Route path="/theme-test" component={ThemeTestPage} />
                  <Route path="/impact" component={CommunityImpactPage} />
                  <Route path="/bookings" component={BookingsListPage} />
                  <Route path="/cleaners" component={CleanersListPage} />
                  <Route path="/cleaners/:slug" component={PublicProfilePage} />
                  <Route path="/city/:cityName" component={CityLandingPage} />
                  <Route
                    path="/services/nettoyage-regulier"
                    component={RegularCleaningPage}
                  />
                  <Route
                    path="/services/nettoyage-profondeur"
                    component={DeepCleaningPage}
                  />
                  <Route
                    path="/services/nettoyage-demenagement"
                    component={MoveInCleaningPage}
                  />
                  <Route
                    path="/services/nettoyage-garage"
                    component={GarageCleaningPage}
                  />
                  <Route path="/book/:cleanerSlug" component={BookingPage} />
                  <Route path="/profile" component={ProfilePage} />
                  <Route
                    path="/profile/calendar"
                    component={ProfileCalendarPage}
                  />
                  <Route path="/profile/payouts" component={PayoutsPage} />
                  <Route path="/profile/stripe-connect/return">
                    {() => {
                      return <RedirectTo path="/profile" />;
                    }}
                  </Route>
                  <Route path="/profile/stripe-connect/refresh">
                    {() => {
                      return <RedirectTo path="/profile" />;
                    }}
                  </Route>

                  <Route path="/onboarding" component={CleanerOnboarding} />
                  <Route
                    path="/client-onboarding"
                    component={ClientOnboarding}
                  />

                  <Route
                    path="/bookings/:bookingId"
                    component={BookingDetailsPage}
                  />
                  <Route
                    path="/bookings/:id/:action"
                    component={BookingConfirmation}
                  />
                  <Route path="/profile/reviews" component={MyReviewsPage} />
                  <Route path="/reviews" component={ReviewsPage} />
                  <Route path="/properties">
                    <RequireClient>
                      <PropertiesPage />
                    </RequireClient>
                  </Route>
                  <Route path="/referrals" component={ReferralsPage} />
                  <Route path="/blog" component={BlogPage} />
                  <Route path="/blog/:slug" component={BlogPostPage} />
                  <Route path="/auth/success">
                    {() => {
                      // Redirect to the previous page after successful OAuth login
                      const redirectTo = getPreviousPage();
                      // Clear the stored previous page
                      clearPreviousPage();
                      // Use the RedirectTo component instead
                      return <RedirectTo path={redirectTo} />;
                    }}
                  </Route>
                  <Route
                    path="/region/:regionId"
                    component={RegionLandingPage}
                  />
                  <Route
                    path="/auth/select-role"
                    component={GoogleRoleSelectionPage}
                  />
                  <Route path="/login">
                    {() => {
                      return <RedirectTo path="/profile" />;
                    }}
                  </Route>
                  <Route path="/register">
                    {() => {
                      return <RedirectTo path="/profile" />;
                    }}
                  </Route>
                  <Route>
                    <div className="container mx-auto py-8 px-4">
                      <h1 className="text-2xl font-bold mb-4">
                        404 - Page non trouvée
                      </h1>
                      <p>La page que vous cherchez n'existe pas.</p>
                    </div>
                  </Route>
                </Switch>
              </Suspense>
            </main>
            {/* Only show footer for routes that aren't onboarding */}
            {location.indexOf("/onboarding") !== 0 &&
              location.indexOf("/client-onboarding") !== 0 && (
                <Footer siteName={siteName} />
              )}
          </Route>
        </Switch>
      </div>
    </HelmetProvider>
  );
}

function NotFoundPage() {
  return (
    <div className="text-center py-12">
      <h2 className="text-3xl font-bold mb-4">Page non trouvée</h2>
      <p className="mb-8">La page que vous cherchez n'existe pas.</p>
      <a
        href="/"
        className="bg-primary text-white px-6 py-2 rounded-md hover:bg-primary-dark transition-colors"
      >
        Retour à l'accueil
      </a>
    </div>
  );
}

export default App;
