import { Link } from "wouter";
import { useUser } from "@/hooks/use-user";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useTranslation } from "react-i18next";
import {
  Menu,
  User,
  LogOut,
  CircleDollarSign,
  Calendar,
  Activity,
  Star,
  Home,
  GiftIcon,
} from "lucide-react";

interface HeaderProps {
  siteName?: string;
}

export default function Header({ siteName = "NettoiGo" }: HeaderProps) {
  const { t } = useTranslation("header");
  const { user, logout } = useUser();

  return (
    <header className="border-b bg-background">
      <div className="container mx-auto px-4">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center gap-8">
            <Link href="/" className="flex items-center gap-2">
              <span className="font-bold text-lg hidden md:inline-block">
                {/* {siteName} */}
                NettoiGo
              </span>
              <img
                src="/images/logo.png"
                alt={siteName}
                className="h-8 w-auto"
              />
            </Link>

            {!user && (
              <nav className="hidden md:flex items-center gap-6">
                <Link
                  href="/homeowners"
                  className="text-slate-600 hover:text-primary"
                >
                  {t("forHomeowners")}
                </Link>
                <Link
                  href="/gallery"
                  className="text-slate-600 hover:text-primary"
                >
                  {t("gallery")}
                </Link>
                <Link
                  href="/cleaners/join"
                  className="text-slate-600 hover:text-primary"
                >
                  {t("becomeCleaner")}
                </Link>
              </nav>
            )}
          </div>

          {user ? (
            <div className="flex items-center gap-4">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    className="relative h-8 w-8 rounded-full"
                  >
                    <Avatar className="h-8 w-8">
                      <AvatarImage
                        src={user.profilePic || undefined}
                        alt={user.name}
                      />
                      <AvatarFallback>{user.name[0]}</AvatarFallback>
                    </Avatar>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-56">
                  <DropdownMenuItem asChild>
                    <Link href="/profile">
                      <div className="flex items-center">
                        <User className="mr-2 h-4 w-4" />
                        <span>{t("myAccount")}</span>
                      </div>
                    </Link>
                  </DropdownMenuItem>
                  {user.role !== "admin" && (
                    <>
                      <DropdownMenuItem asChild>
                        <Link href="/bookings">
                          <div className="flex items-center">
                            <Calendar className="mr-2 h-4 w-4" />
                            <span>{t("myBookings")}</span>
                          </div>
                        </Link>
                      </DropdownMenuItem>
                    </>
                  )}
                  {user.role === "client" && (
                    <DropdownMenuItem asChild>
                      <Link href="/properties">
                        <div className="flex items-center">
                          <Home className="mr-2 h-4 w-4" />
                          <span>{t("properties")}</span>
                        </div>
                      </Link>
                    </DropdownMenuItem>
                  )}
                  {user.role === "cleaner" && (
                    <DropdownMenuItem asChild>
                      <Link href="/profile/payouts">
                        <div className="flex items-center">
                          <CircleDollarSign className="mr-2 h-4 w-4" />
                          <span>{t("earningsPayouts")}</span>
                        </div>
                      </Link>
                    </DropdownMenuItem>
                  )}
                  {user.role === "admin" && (
                    <>
                      <DropdownMenuItem asChild>
                        <Link href="/admin">
                          <div className="flex items-center">
                            <Menu className="mr-2 h-4 w-4" />
                            <span>{t("adminDashboard")}</span>
                          </div>
                        </Link>
                      </DropdownMenuItem>
                      <DropdownMenuItem asChild>
                        <Link href="/admin/health">
                          <div className="flex items-center">
                            <Activity className="mr-2 h-4 w-4" />
                            <span>{t("systemHealth")}</span>
                          </div>
                        </Link>
                      </DropdownMenuItem>
                    </>
                  )}
                  <DropdownMenuItem asChild>
                    <Link href="/referrals">
                      <div className="flex items-center">
                        <GiftIcon className="mr-2 h-4 w-4" />
                        <span>{t("referralProgram")}</span>
                      </div>
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => logout()}>
                    <LogOut className="mr-2 h-4 w-4" />
                    <span>{t("logout")}</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          ) : (
            <div className="flex items-center gap-4">
              <Button variant="outline" asChild>
                <Link href="/cleaners/join">{t("becomeCleaner")}</Link>
              </Button>
              <Button asChild>
                <Link href="/login">{t("login")}</Link>
              </Button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
