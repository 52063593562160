// Define user location interface
export interface UserLocation {
  city?: string;
  province?: string;
  country?: string;
  postalCode?: string;
}

// Cache key for storing user location
const USER_LOCATION_CACHE_KEY = "user_location";

/**
 * Gets user's location from cache
 * @returns {UserLocation|null} User location if available, null otherwise
 */
export function getUserLocation(): UserLocation | null {
  try {
    const cachedLocation = localStorage.getItem(USER_LOCATION_CACHE_KEY);
    if (cachedLocation) {
      return JSON.parse(cachedLocation) as UserLocation;
    }
  } catch (error) {
    console.error("Error retrieving user location from cache:", error);
  }
  return null;
}

/**
 * Saves user's location to cache
 * @param {UserLocation} location User's location data
 */
export function saveUserLocation(location: UserLocation): void {
  try {
    localStorage.setItem(USER_LOCATION_CACHE_KEY, JSON.stringify(location));
  } catch (error) {
    console.error("Error saving user location to cache:", error);
  }
}

/**
 * Checks if a postal code is within Quebec
 * @param {string} postalCode Canadian postal code
 * @returns {boolean} True if postal code is in Quebec
 */
export function isQuebecPostalCode(postalCode: string): boolean {
  if (!postalCode || typeof postalCode !== "string") {
    return false;
  }

  // Quebec postal codes start with G, H, or J
  const firstChar = postalCode.trim().toUpperCase().charAt(0);
  return ["G", "H", "J"].includes(firstChar);
}

/**
 * Determines if a location is in Quebec
 * @param {UserLocation} location Location data
 * @returns {boolean} True if location is in Quebec
 */
export function isLocationInQuebec(location: UserLocation | null): boolean {
  if (!location) return false;

  // Check by province
  if (location.province) {
    const province = location.province.toLowerCase();
    if (province === "quebec" || province === "québec" || province === "qc") {
      return true;
    }
  }

  // Check by postal code
  if (location.postalCode) {
    return isQuebecPostalCode(location.postalCode);
  }

  return false;
}
