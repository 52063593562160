import { Link } from "wouter";
import { useTranslation } from "react-i18next";
import ThemeToggle from "./ThemeToggle";

// We're removing the TranslationLoader component and handling namespaces directly
interface FooterProps {
  siteName?: string;
}

export default function Footer({ siteName = "NettoiGo" }: FooterProps) {
  // Load multiple namespaces directly in the component
  const { t } = useTranslation(["footer", "booking"]);

  return (
    <footer className="bg-primary-foreground mt-auto">
      <div className="container mx-auto py-12 px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="col-span-1">
            <Link href="/" className="flex items-center gap-2 mb-4">
              <img
                src="/images/logo.png"
                alt={siteName}
                className="h-8 w-auto"
              />
              <span className="font-bold text-lg">
                {/* {siteName} */}
                NettoiGo
              </span>
            </Link>
            <p className="text-muted-foreground mb-4">
              {t("footer:description")}
            </p>
            <ul className="space-y-2 text-sm mt-4">
              <li>
                <Link href="/impact">{t("footer:communityImpact")}</Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold mb-4">{t("booking:frequency")}</h4>
            <ul className="space-y-2 text-sm">
              <li>
                <Link href="/about">{t("footer:aboutUs")}</Link>
              </li>
              <li>
                <Link href="/how-it-works">{t("footer:howItWorks")}</Link>
              </li>
              <li>
                <Link href="/cleaners">{t("footer:bookNow")}</Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold mb-4">{t("footer:support")}</h4>
            <ul className="space-y-2 text-sm">
              <li>
                <Link href="/faq">{t("footer:faq")}</Link>
              </li>
              <li>
                <Link href="/gallery">{t("footer:gallery")}</Link>
              </li>
              <li>
                <Link href="/contact">{t("footer:contactUs")}</Link>
              </li>
              <li>
                <Link href="/blog">{t("footer:blog")}</Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold mb-4">{t("footer:legal")}</h4>
            <ul className="space-y-2 text-sm">
              <li>
                <Link href="/privacy">{t("footer:privacyPolicy")}</Link>
              </li>
              <li>
                <Link href="/terms">{t("footer:termsOfService")}</Link>
              </li>
              <li>
                <Link href="/referral-program">
                  {t("common:referralProgramTitle")}
                </Link>
              </li>
            </ul>
          </div>
          <ThemeToggle />
        </div>

        <div className="border-t mt-8 pt-6 text-center">
          <p className="text-sm text-muted-foreground">
            &copy; {new Date().getFullYear()} {siteName}.{" "}
            {t("footer:allRightsReserved")}
          </p>
        </div>
      </div>
    </footer>
  );
}
