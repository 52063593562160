import { getUserLocation } from "./location";

/**
 * Checks if a user should be redirected to a different domain based on
 * their location and language preferences.
 *
 * - If user is in Quebec and using French, they should be on nettoigo.ca
 * - If user is outside Quebec, they should be on weklean.ca
 *
 * @returns {boolean} True if redirect was performed, false otherwise
 */
export function checkDomainRedirect(): boolean {
  // Skip in development environment
  if (process.env.NODE_ENV !== "production") {
    return false;
  }

  // Get current hostname
  const hostname = window.location.hostname;

  // If not on either production domain, don't redirect
  if (hostname !== "weklean.ca" && hostname !== "nettoigo.ca") {
    return false;
  }

  // Get preferred language from localStorage
  const preferredLang =
    localStorage.getItem("i18nextLng") || navigator.language.split("-")[0];

  // Try to determine if user is in Quebec
  const userLocation = getUserLocation();
  const userInQuebec =
    userLocation?.province === "Quebec" || userLocation?.province === "Québec";

  // Determine if redirect is needed
  let shouldRedirect = false;
  let targetDomain = "";

  if (userInQuebec && preferredLang === "fr" && hostname === "weklean.ca") {
    // Quebec user with French preference on weklean.ca - redirect to nettoigo.ca
    shouldRedirect = true;
    targetDomain = "nettoigo.ca";
  } else if (
    (!userInQuebec || preferredLang !== "fr") &&
    hostname === "nettoigo.ca"
  ) {
    // Non-Quebec user or non-French user on nettoigo.ca - redirect to weklean.ca
    shouldRedirect = true;
    targetDomain = "weklean.ca";
  }

  // Perform redirect if needed
  if (shouldRedirect && targetDomain) {
    const currentPath = window.location.pathname;
    const currentSearch = window.location.search;
    window.location.href = `https://${targetDomain}${currentPath}${currentSearch}`;
    return true;
  }

  return false;
}

/**
 * Gets the site name based on current domain
 * @returns {string} "NettoiGo" or "WeKlean"
 */
export function getSiteName(): string {
  const hostname =
    typeof window !== "undefined" ? window.location.hostname : "";
  return hostname === "nettoigo.ca" ? "NettoiGo" : "WeKlean";
}
