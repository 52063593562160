import * as React from "react";
import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./lib/queryClient";
import { Toaster } from "@/components/ui/toaster";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import "./index.css";
import "./bright-theme.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./lib/i18n";

// Get browser language and check if it's supported
const browserLang = navigator.language.split("-")[0];
const supportedLangs = ["fr", "en"];
const defaultLang = supportedLangs.includes(browserLang) ? browserLang : "fr";

// Only set language if not already set
if (!localStorage.getItem("i18nextLng")) {
  localStorage.setItem("i18nextLng", defaultLang);
}

// Add error handling for i18n initialization
try {
  // Set the language in i18n without reinitializing
  i18n.changeLanguage(localStorage.getItem("i18nextLng") || defaultLang);
  console.log("i18n initialized successfully with language:", i18n.language);
} catch (error) {
  console.error("Error initializing i18n:", error);
}

// Apply theme from localStorage
const applyThemeFromStorage = () => {
  const savedTheme = localStorage.getItem("weklean-theme");
  const root = document.documentElement;

  // Remove all theme classes first
  root.classList.remove("bright-theme", "dark");

  // Apply the saved theme
  if (savedTheme === "bright") {
    root.classList.add("bright-theme");
  } else if (savedTheme === "dark") {
    root.classList.add("dark");
  }
};

// Apply theme immediately
applyThemeFromStorage();

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <App />
          <Toaster />
        </QueryClientProvider>
      </I18nextProvider>
    </HelmetProvider>
  </StrictMode>
);
